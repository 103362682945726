import { createStore } from 'vuex';
import axios from 'axios';
import api from '@/store/api';

export default createStore({
  state: {
    submitted: false
  },
  getters: {},
  mutations: {
    SET_STATE (state, {key, value}) {
      state[key] = value
    }
  },
  actions: {
    async save_analytics ({ commit }, data) {
      try {
        await axios.post(api.analytics, data);
      } catch (err) {}
    },
    async select_partnership ({ commit }, form) {
      try {
        const { data, status } = await axios.post(api.analytics, form)
        commit('SET_STATE', {key: 'submitted', value: true})
      } catch (err) {}
    }
  },
  modules: {}
});
