import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import BaseInput from '@/components/BaseInput';
import BaseCheckbox from '@/components/BaseCheckbox';
import './styles/helper-classes.scss';
import './styles/typography.scss';
import './styles/base.scss';

const app = createApp(App);

app
  .component('BaseInput', BaseInput)
  .component('BaseCheckbox', BaseCheckbox)
  .use(store)
  .use(router)
  .mount('#app');
