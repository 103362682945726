<template>
  <label class="base-checkbox flex">
    <input type="checkbox" :checked="checked" @change="handleChange" />
    <span>{{ label || name }}</span>
  </label>
</template>

<script setup>
  const props = defineProps({
    name: String,
    label: String,
    checked: Boolean
  });

  const emits = defineEmits(['change']);

  function handleChange (ev) {
    emits('change', { name: props.name, value: ev.target.checked });
  }
</script>

<style lang="scss">
  .base-checkbox {
    @media only screen and (min-width: 0) {
      input {
        margin-right: 8px;
      }
      span {
        font-size: 1.8rem;
        font-weight: 200;
      }
    }
  }
</style>
